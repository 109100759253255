namespace $ {
	export class $hyoo_scout extends $mol_book2 {
		
		/**
		 * ```tree
		 * data /any
		 * ```
		 */
		data() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * plugins / <= Theme
		 * ```
		 */
		plugins() {
			return [
				this.Theme()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * pages /
		 * 	<= Filter
		 * 	<= Gists
		 * 	<= Suggest
		 * ```
		 */
		pages() {
			return [
				this.Filter(),
				this.Gists(),
				this.Suggest()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Filter_aspect* $mol_list rows /
		 * 	<= Filter_aspect_title*
		 * 	<= Filter_aspect_tags*
		 * ```
		 */
		@ $mol_mem_key
		Filter_aspect(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Filter_aspect_title(id),
				this.Filter_aspect_tags(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Filter_tag* $mol_check
		 * 	checked?val <=> filter_tag_checked*?val
		 * 	label / <= Filter_tag_title*
		 * ```
		 */
		@ $mol_mem_key
		Filter_tag(id: any) {
			const obj = new this.$.$mol_check()
			
			obj.checked = (val?: any) => this.filter_tag_checked(id, val)
			obj.label = () => [
				this.Filter_tag_title(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Gist_link* $mol_link
		 * 	sub /
		 * 		<= Gist_link_icon*
		 * 		<= Gist_link_title*
		 * 	arg *
		 * 		gist <= key*
		 * 		suggest null
		 * ```
		 */
		@ $mol_mem_key
		Gist_link(id: any) {
			const obj = new this.$.$mol_link()
			
			obj.sub = () => [
				this.Gist_link_icon(id),
				this.Gist_link_title(id)
			] as readonly any[]
			obj.arg = () => ({
				gist: this.key(id),
				suggest: null as any
			})
			
			return obj
		}
		
		/**
		 * ```tree
		 * Gist* $mol_page
		 * 	title <= gist_title*
		 * 	tools /
		 * 		<= Gist_favorite*
		 * 		<= Gist_close
		 * 	body /
		 * 		<= Gist_content*
		 * 		<= Gist_aspects*
		 * 		<= Gist_remarks*
		 * ```
		 */
		@ $mol_mem_key
		Gist(id: any) {
			const obj = new this.$.$mol_page()
			
			obj.title = () => this.gist_title(id)
			obj.tools = () => [
				this.Gist_favorite(id),
				this.Gist_close()
			] as readonly any[]
			obj.body = () => [
				this.Gist_content(id),
				this.Gist_aspects(id),
				this.Gist_remarks(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Gist_aspect* $mol_row sub /
		 * 	<= Gist_aspect_title*
		 * 	<= Gist_aspect_tags*
		 * ```
		 */
		@ $mol_mem_key
		Gist_aspect(id: any) {
			const obj = new this.$.$mol_row()
			
			obj.sub = () => [
				this.Gist_aspect_title(id),
				this.Gist_aspect_tags(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Gist_tag* $mol_view
		 * 	attr * hyoo_scout_tag_id <= tag_title*
		 * 	sub / <= tag_title*
		 * ```
		 */
		@ $mol_mem_key
		Gist_tag(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.attr = () => ({
				hyoo_scout_tag_id: this.tag_title(id)
			})
			obj.sub = () => [
				this.tag_title(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Theme $mol_theme_auto
		 * ```
		 */
		@ $mol_mem
		Theme() {
			const obj = new this.$.$mol_theme_auto()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Source $mol_link_source uri \https://github.com/hyoo-ru/scout.hyoo.ru/
		 * ```
		 */
		@ $mol_mem
		Source() {
			const obj = new this.$.$mol_link_source()
			
			obj.uri = () => "https://github.com/hyoo-ru/scout.hyoo.ru/"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Lights $mol_lights_toggle
		 * ```
		 */
		@ $mol_mem
		Lights() {
			const obj = new this.$.$mol_lights_toggle()
			
			return obj
		}
		
		/**
		 * ```tree
		 * filter_aspects /
		 * ```
		 */
		filter_aspects() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Filter_aspects $mol_list rows <= filter_aspects
		 * ```
		 */
		@ $mol_mem
		Filter_aspects() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.filter_aspects()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Feedback $mol_link
		 * 	title \Ошибки? Предложения?
		 * 	uri \https://vk.com/scout_hyoo_ru
		 * ```
		 */
		@ $mol_mem
		Feedback() {
			const obj = new this.$.$mol_link()
			
			obj.title = () => "Ошибки? Предложения?"
			obj.uri = () => "https://vk.com/scout_hyoo_ru"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Filter $mol_page
		 * 	title \Фильтры
		 * 	tools /
		 * 		<= Source
		 * 		<= Lights
		 * 	body /
		 * 		<= Filter_aspects
		 * 		<= Feedback
		 * ```
		 */
		@ $mol_mem
		Filter() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "Фильтры"
			obj.tools = () => [
				this.Source(),
				this.Lights()
			] as readonly any[]
			obj.body = () => [
				this.Filter_aspects(),
				this.Feedback()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * gists_title \Игротека вожатого
		 * ```
		 */
		gists_title() {
			return "Игротека вожатого"
		}
		
		/**
		 * ```tree
		 * Suggest_icon $mol_icon_plus
		 * ```
		 */
		@ $mol_mem
		Suggest_icon() {
			const obj = new this.$.$mol_icon_plus()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Suggest_link $mol_link
		 * 	arg *
		 * 		suggest \
		 * 		gist null
		 * 	sub / <= Suggest_icon
		 * ```
		 */
		@ $mol_mem
		Suggest_link() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				suggest: "",
				gist: null as any
			})
			obj.sub = () => [
				this.Suggest_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * gists_favorite?val false
		 * ```
		 */
		@ $mol_mem
		gists_favorite(val?: any) {
			if ( val !== undefined ) return val as never
			return false
		}
		
		/**
		 * ```tree
		 * Gists_favorite_icon $mol_icon_favorite
		 * ```
		 */
		@ $mol_mem
		Gists_favorite_icon() {
			const obj = new this.$.$mol_icon_favorite()
			
			return obj
		}
		
		/**
		 * ```tree
		 * gists_favorite_duration \
		 * ```
		 */
		gists_favorite_duration() {
			return ""
		}
		
		/**
		 * ```tree
		 * Gists_favorite_duration $mol_view sub / <= gists_favorite_duration
		 * ```
		 */
		@ $mol_mem
		Gists_favorite_duration() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.gists_favorite_duration()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * gists_favorite_label / <= Gists_favorite_duration
		 * ```
		 */
		gists_favorite_label() {
			return [
				this.Gists_favorite_duration()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Gists_favorite $mol_check_icon
		 * 	hint \Только избранное
		 * 	checked?val <=> gists_favorite?val
		 * 	Icon <= Gists_favorite_icon
		 * 	label <= gists_favorite_label
		 * ```
		 */
		@ $mol_mem
		Gists_favorite() {
			const obj = new this.$.$mol_check_icon()
			
			obj.hint = () => "Только избранное"
			obj.checked = (val?: any) => this.gists_favorite(val)
			obj.Icon = () => this.Gists_favorite_icon()
			obj.label = () => this.gists_favorite_label()
			
			return obj
		}
		
		/**
		 * ```tree
		 * gists_filter_query?val \
		 * ```
		 */
		@ $mol_mem
		gists_filter_query(val?: any) {
			if ( val !== undefined ) return val as never
			return ""
		}
		
		/**
		 * ```tree
		 * Gists_search $mol_search query?val <=> gists_filter_query?val
		 * ```
		 */
		@ $mol_mem
		Gists_search() {
			const obj = new this.$.$mol_search()
			
			obj.query = (val?: any) => this.gists_filter_query(val)
			
			return obj
		}
		
		/**
		 * ```tree
		 * gist_links /
		 * ```
		 */
		gist_links() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Gists_list $mol_list rows <= gist_links
		 * ```
		 */
		@ $mol_mem
		Gists_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.gist_links()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Gists $mol_page
		 * 	title <= gists_title
		 * 	tools /
		 * 		<= Suggest_link
		 * 		<= Gists_favorite
		 * 	body /
		 * 		<= Gists_search
		 * 		<= Gists_list
		 * ```
		 */
		@ $mol_mem
		Gists() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => this.gists_title()
			obj.tools = () => [
				this.Suggest_link(),
				this.Gists_favorite()
			] as readonly any[]
			obj.body = () => [
				this.Gists_search(),
				this.Gists_list()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Suggest $hyoo_scout_suggest
		 * ```
		 */
		@ $mol_mem
		Suggest() {
			const obj = new this.$.$hyoo_scout_suggest()
			
			return obj
		}
		
		/**
		 * ```tree
		 * key* \
		 * ```
		 */
		key(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Filter_aspect_title* $mol_view sub / <= key*
		 * ```
		 */
		@ $mol_mem_key
		Filter_aspect_title(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.key(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * filter_aspect_tags* /
		 * ```
		 */
		filter_aspect_tags(id: any) {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Filter_aspect_tags* $mol_row sub <= filter_aspect_tags*
		 * ```
		 */
		@ $mol_mem_key
		Filter_aspect_tags(id: any) {
			const obj = new this.$.$mol_row()
			
			obj.sub = () => this.filter_aspect_tags(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * filter_tag_checked*?val true
		 * ```
		 */
		@ $mol_mem_key
		filter_tag_checked(id: any, val?: any) {
			if ( val !== undefined ) return val as never
			return true
		}
		
		/**
		 * ```tree
		 * tag_title* \
		 * ```
		 */
		tag_title(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Filter_tag_title* $mol_view
		 * 	attr * hyoo_scout_tag_id <= tag_title*
		 * 	sub / <= tag_title*
		 * ```
		 */
		@ $mol_mem_key
		Filter_tag_title(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.attr = () => ({
				hyoo_scout_tag_id: this.tag_title(id)
			})
			obj.sub = () => [
				this.tag_title(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * gist_icon* \
		 * ```
		 */
		gist_icon(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Gist_link_icon* $mol_view sub / <= gist_icon*
		 * ```
		 */
		@ $mol_mem_key
		Gist_link_icon(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.gist_icon(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * gist_title* \
		 * ```
		 */
		gist_title(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Gist_link_title* $mol_dimmer
		 * 	needle <= gists_filter_query
		 * 	haystack <= gist_title*
		 * ```
		 */
		@ $mol_mem_key
		Gist_link_title(id: any) {
			const obj = new this.$.$mol_dimmer()
			
			obj.needle = () => this.gists_filter_query()
			obj.haystack = () => this.gist_title(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * gist_favorite*?val false
		 * ```
		 */
		@ $mol_mem_key
		gist_favorite(id: any, val?: any) {
			if ( val !== undefined ) return val as never
			return false
		}
		
		/**
		 * ```tree
		 * Gist_favorite_icon $mol_icon_favorite
		 * ```
		 */
		@ $mol_mem
		Gist_favorite_icon() {
			const obj = new this.$.$mol_icon_favorite()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Gist_favorite* $mol_check_icon
		 * 	checked?val <=> gist_favorite*?val
		 * 	Icon <= Gist_favorite_icon
		 * ```
		 */
		@ $mol_mem_key
		Gist_favorite(id: any) {
			const obj = new this.$.$mol_check_icon()
			
			obj.checked = (val?: any) => this.gist_favorite(id, val)
			obj.Icon = () => this.Gist_favorite_icon()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Gist_close_icon $mol_icon_cross
		 * ```
		 */
		@ $mol_mem
		Gist_close_icon() {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Gist_close $mol_link
		 * 	arg * gist null
		 * 	sub / <= Gist_close_icon
		 * ```
		 */
		@ $mol_mem
		Gist_close() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				gist: null as any
			})
			obj.sub = () => [
				this.Gist_close_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * gist_content* \
		 * ```
		 */
		gist_content(id: any) {
			return ""
		}
		
		/**
		 * ```tree
		 * Gist_content* $mol_text text <= gist_content*
		 * ```
		 */
		@ $mol_mem_key
		Gist_content(id: any) {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.gist_content(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * gist_aspects* /
		 * ```
		 */
		gist_aspects(id: any) {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Gist_aspects* $mol_list rows <= gist_aspects*
		 * ```
		 */
		@ $mol_mem_key
		Gist_aspects(id: any) {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.gist_aspects(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * gist_remarks*?val \
		 * ```
		 */
		@ $mol_mem_key
		gist_remarks(id: any, val?: any) {
			if ( val !== undefined ) return val as never
			return ""
		}
		
		/**
		 * ```tree
		 * Gist_remarks* $mol_textarea
		 * 	hint \Личные заметки
		 * 	value?val <=> gist_remarks*?val
		 * ```
		 */
		@ $mol_mem_key
		Gist_remarks(id: any) {
			const obj = new this.$.$mol_textarea()
			
			obj.hint = () => "Личные заметки"
			obj.value = (val?: any) => this.gist_remarks(id, val)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Gist_aspect_title* $mol_view sub /
		 * 	<= key*
		 * 	\:
		 * ```
		 */
		@ $mol_mem_key
		Gist_aspect_title(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.key(id),
				": "
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * gist_aspect_tags* /
		 * ```
		 */
		gist_aspect_tags(id: any) {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Gist_aspect_tags* $mol_view sub <= gist_aspect_tags*
		 * ```
		 */
		@ $mol_mem_key
		Gist_aspect_tags(id: any) {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => this.gist_aspect_tags(id)
			
			return obj
		}
	}
	
}

