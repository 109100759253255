namespace $ {
	export class $hyoo_scout_suggest extends $mol_page {
		
		/**
		 * ```tree
		 * title \Новая игра
		 * ```
		 */
		title() {
			return "Новая игра"
		}
		
		/**
		 * ```tree
		 * minimal_width 400
		 * ```
		 */
		minimal_width() {
			return 400
		}
		
		/**
		 * ```tree
		 * tools / <= Close
		 * ```
		 */
		tools() {
			return [
				this.Close()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * body / <= Form
		 * ```
		 */
		body() {
			return [
				this.Form()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * close_icon $mol_icon_cross
		 * ```
		 */
		@ $mol_mem
		close_icon() {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Close $mol_link
		 * 	arg * suggest null
		 * 	sub / <= close_icon
		 * ```
		 */
		@ $mol_mem
		Close() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				suggest: null as any
			})
			obj.sub = () => [
				this.close_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * name_bid \
		 * ```
		 */
		name_bid() {
			return ""
		}
		
		/**
		 * ```tree
		 * name?val \
		 * ```
		 */
		@ $mol_mem
		name(val?: any) {
			if ( val !== undefined ) return val as never
			return ""
		}
		
		/**
		 * ```tree
		 * Name $mol_string
		 * 	hint \Раз два три
		 * 	value?val <=> name?val
		 * ```
		 */
		@ $mol_mem
		Name() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => "Раз два три"
			obj.value = (val?: any) => this.name(val)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Name_field $mol_form_field
		 * 	name \Название
		 * 	bid <= name_bid
		 * 	control <= Name
		 * ```
		 */
		@ $mol_mem
		Name_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Название"
			obj.bid = () => this.name_bid()
			obj.control = () => this.Name()
			
			return obj
		}
		
		/**
		 * ```tree
		 * icon_bid \
		 * ```
		 */
		icon_bid() {
			return ""
		}
		
		/**
		 * ```tree
		 * icon?val \
		 * ```
		 */
		@ $mol_mem
		icon(val?: any) {
			if ( val !== undefined ) return val as never
			return ""
		}
		
		/**
		 * ```tree
		 * Icon $mol_string
		 * 	length_max 2
		 * 	hint \?
		 * 	value?val <=> icon?val
		 * ```
		 */
		@ $mol_mem
		Icon() {
			const obj = new this.$.$mol_string()
			
			obj.length_max = () => 2
			obj.hint = () => "?"
			obj.value = (val?: any) => this.icon(val)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Icon_field $mol_form_field
		 * 	name \Эмодзи
		 * 	bid <= icon_bid
		 * 	control <= Icon
		 * ```
		 */
		@ $mol_mem
		Icon_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Эмодзи"
			obj.bid = () => this.icon_bid()
			obj.control = () => this.Icon()
			
			return obj
		}
		
		/**
		 * ```tree
		 * descr_bid \
		 * ```
		 */
		descr_bid() {
			return ""
		}
		
		/**
		 * ```tree
		 * descr?val \
		 * ```
		 */
		@ $mol_mem
		descr(val?: any) {
			if ( val !== undefined ) return val as never
			return ""
		}
		
		/**
		 * ```tree
		 * Descr $mol_textarea
		 * 	hint \Вместе считаем - вот и вся игра.
		 * 	value?val <=> descr?val
		 * ```
		 */
		@ $mol_mem
		Descr() {
			const obj = new this.$.$mol_textarea()
			
			obj.hint = () => "Вместе считаем - вот и вся игра."
			obj.value = (val?: any) => this.descr(val)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Descr_field $mol_form_field
		 * 	name \Описание
		 * 	bid <= descr_bid
		 * 	control <= Descr
		 * ```
		 */
		@ $mol_mem
		Descr_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Описание"
			obj.bid = () => this.descr_bid()
			obj.control = () => this.Descr()
			
			return obj
		}
		
		/**
		 * ```tree
		 * reason_bid \
		 * ```
		 */
		reason_bid() {
			return ""
		}
		
		/**
		 * ```tree
		 * reason?val \
		 * ```
		 */
		@ $mol_mem
		reason(val?: any) {
			if ( val !== undefined ) return val as never
			return ""
		}
		
		/**
		 * ```tree
		 * Reason $mol_switch
		 * 	value?val <=> reason?val
		 * 	options *
		 * 		Внимание \Внимание
		 * 		Группировка \Группировка
		 * 		Доверие \Доверие
		 * 		Знакомство \Знакомство
		 * 		Лидерство \Лидерство
		 * 		Мышление \Мышление
		 * 		Обратная_связь \Обратная связь
		 * 		Обсуждение \Обсуждение
		 * 		Развлечение \Развлечение
		 * 		Разминка \Разминка
		 * 		Раскрепощение \Раскрепощение
		 * 		Сплочение \Сплочение
		 * 		Творчество \Творчество
		 * 		Усвоение \Усвоение
		 * ```
		 */
		@ $mol_mem
		Reason() {
			const obj = new this.$.$mol_switch()
			
			obj.value = (val?: any) => this.reason(val)
			obj.options = () => ({
				"Внимание": "Внимание",
				"Группировка": "Группировка",
				"Доверие": "Доверие",
				"Знакомство": "Знакомство",
				"Лидерство": "Лидерство",
				"Мышление": "Мышление",
				"Обратная_связь": "Обратная связь",
				"Обсуждение": "Обсуждение",
				"Развлечение": "Развлечение",
				"Разминка": "Разминка",
				"Раскрепощение": "Раскрепощение",
				"Сплочение": "Сплочение",
				"Творчество": "Творчество",
				"Усвоение": "Усвоение"
			})
			
			return obj
		}
		
		/**
		 * ```tree
		 * Reason_field $mol_form_field
		 * 	name \Цель
		 * 	bid <= reason_bid
		 * 	control <= Reason
		 * ```
		 */
		@ $mol_mem
		Reason_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Цель"
			obj.bid = () => this.reason_bid()
			obj.control = () => this.Reason()
			
			return obj
		}
		
		/**
		 * ```tree
		 * age?val \any
		 * ```
		 */
		@ $mol_mem
		age(val?: any) {
			if ( val !== undefined ) return val as never
			return "any"
		}
		
		/**
		 * ```tree
		 * Age $mol_switch
		 * 	value?val <=> age?val
		 * 	options *
		 * 		low \Малыши
		 * 		mid \Среднячки
		 * 		high \Старшаки
		 * 		any \Любой
		 * ```
		 */
		@ $mol_mem
		Age() {
			const obj = new this.$.$mol_switch()
			
			obj.value = (val?: any) => this.age(val)
			obj.options = () => ({
				low: "Малыши",
				mid: "Среднячки",
				high: "Старшаки",
				any: "Любой"
			})
			
			return obj
		}
		
		/**
		 * ```tree
		 * Age_field $mol_form_field
		 * 	name \Возраст
		 * 	control <= Age
		 * ```
		 */
		@ $mol_mem
		Age_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Возраст"
			obj.control = () => this.Age()
			
			return obj
		}
		
		/**
		 * ```tree
		 * place?val \any
		 * ```
		 */
		@ $mol_mem
		place(val?: any) {
			if ( val !== undefined ) return val as never
			return "any"
		}
		
		/**
		 * ```tree
		 * Place $mol_switch
		 * 	value?val <=> place?val
		 * 	options *
		 * 		hall \Зал
		 * 		space \Просторное
		 * 		quiet \Тихое
		 * 		any \Любое
		 * ```
		 */
		@ $mol_mem
		Place() {
			const obj = new this.$.$mol_switch()
			
			obj.value = (val?: any) => this.place(val)
			obj.options = () => ({
				hall: "Зал",
				space: "Просторное",
				quiet: "Тихое",
				any: "Любое"
			})
			
			return obj
		}
		
		/**
		 * ```tree
		 * Place_field $mol_form_field
		 * 	name \Место
		 * 	control <= Place
		 * ```
		 */
		@ $mol_mem
		Place_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Место"
			obj.control = () => this.Place()
			
			return obj
		}
		
		/**
		 * ```tree
		 * stuff?val \
		 * ```
		 */
		@ $mol_mem
		stuff(val?: any) {
			if ( val !== undefined ) return val as never
			return ""
		}
		
		/**
		 * ```tree
		 * Stuff $mol_string
		 * 	hint \Руки, Ноги, Голова
		 * 	value?val <=> stuff?val
		 * ```
		 */
		@ $mol_mem
		Stuff() {
			const obj = new this.$.$mol_string()
			
			obj.hint = () => "Руки, Ноги, Голова"
			obj.value = (val?: any) => this.stuff(val)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Stuff_field $mol_form_field
		 * 	name \Реквизит
		 * 	control <= Stuff
		 * ```
		 */
		@ $mol_mem
		Stuff_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Реквизит"
			obj.control = () => this.Stuff()
			
			return obj
		}
		
		/**
		 * ```tree
		 * prep_bid \
		 * ```
		 */
		prep_bid() {
			return ""
		}
		
		/**
		 * ```tree
		 * prep?val \0
		 * ```
		 */
		@ $mol_mem
		prep(val?: any) {
			if ( val !== undefined ) return val as never
			return "0"
		}
		
		/**
		 * ```tree
		 * Prep $mol_switch
		 * 	value?val <=> prep?val
		 * 	options *
		 * 		0 \Нет
		 * 		1 \1 минута
		 * 		5 \5 минут
		 * 		10 \10 минут
		 * 		20 \20 минут
		 * 		40 \40 минут
		 * ```
		 */
		@ $mol_mem
		Prep() {
			const obj = new this.$.$mol_switch()
			
			obj.value = (val?: any) => this.prep(val)
			obj.options = () => ({
				0: "Нет",
				1: "1 минута",
				5: "5 минут",
				10: "10 минут",
				20: "20 минут",
				40: "40 минут"
			})
			
			return obj
		}
		
		/**
		 * ```tree
		 * Prep_field $mol_form_field
		 * 	name \Подготовка
		 * 	bid <= prep_bid
		 * 	control <= Prep
		 * ```
		 */
		@ $mol_mem
		Prep_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Подготовка"
			obj.bid = () => this.prep_bid()
			obj.control = () => this.Prep()
			
			return obj
		}
		
		/**
		 * ```tree
		 * duration?val \10
		 * ```
		 */
		@ $mol_mem
		duration(val?: any) {
			if ( val !== undefined ) return val as never
			return "10"
		}
		
		/**
		 * ```tree
		 * Duration $mol_switch
		 * 	value?val <=> duration?val
		 * 	options *
		 * 		1 \1 минута
		 * 		5 \5 минут
		 * 		10 \10 минут
		 * 		20 \20 минут
		 * 		40 \40 минут
		 * 		60 \60 минут
		 * ```
		 */
		@ $mol_mem
		Duration() {
			const obj = new this.$.$mol_switch()
			
			obj.value = (val?: any) => this.duration(val)
			obj.options = () => ({
				1: "1 минута",
				5: "5 минут",
				10: "10 минут",
				20: "20 минут",
				40: "40 минут",
				60: "60 минут"
			})
			
			return obj
		}
		
		/**
		 * ```tree
		 * Duration_field $mol_form_field
		 * 	name \Длительность
		 * 	control <= Duration
		 * ```
		 */
		@ $mol_mem
		Duration_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => "Длительность"
			obj.control = () => this.Duration()
			
			return obj
		}
		
		/**
		 * ```tree
		 * submit?val null
		 * ```
		 */
		@ $mol_mem
		submit(val?: any) {
			if ( val !== undefined ) return val as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Submit $mol_button_major
		 * 	sub / \Добавить игру
		 * 	event_click?val <=> submit?val
		 * 	disabled <= submit_blocked
		 * ```
		 */
		@ $mol_mem
		Submit() {
			const obj = new this.$.$mol_button_major()
			
			obj.sub = () => [
				"Добавить игру"
			] as readonly any[]
			obj.event_click = (val?: any) => this.submit(val)
			obj.disabled = () => this.submit_blocked()
			
			return obj
		}
		
		/**
		 * ```tree
		 * submit_blocked
		 * ```
		 */
		submit_blocked() {
			return this.Form().submit_blocked()
		}
		
		/**
		 * ```tree
		 * Form $mol_form
		 * 	form_fields /
		 * 		<= Name_field
		 * 		<= Icon_field
		 * 		<= Descr_field
		 * 		<= Reason_field
		 * 		<= Age_field
		 * 		<= Place_field
		 * 		<= Stuff_field
		 * 		<= Prep_field
		 * 		<= Duration_field
		 * 	submit_blocked => submit_blocked
		 * 	buttons / <= Submit
		 * ```
		 */
		@ $mol_mem
		Form() {
			const obj = new this.$.$mol_form()
			
			obj.form_fields = () => [
				this.Name_field(),
				this.Icon_field(),
				this.Descr_field(),
				this.Reason_field(),
				this.Age_field(),
				this.Place_field(),
				this.Stuff_field(),
				this.Prep_field(),
				this.Duration_field()
			] as readonly any[]
			obj.buttons = () => [
				this.Submit()
			] as readonly any[]
			
			return obj
		}
	}
	
}

